<template>
  <div class="o-layout u-margin-bottom-large">
    <div class="o-layout__item">
      <table class="o-table o-table--fixed c-table--results">
        <thead>
          <tr>
            <th class="c-table-cell__valign-bottom c-table-text__regular">
            </th>
            <th :colspan="data.types.length">
              {{ round.name }}
            </th>
          </tr>
          <tr>
            <th class="c-table-cell__valign-bottom c-table-text__regular">
              {{$t('category')}}
            </th>
            <th v-for="type in data.types"
                :key="getTypeKey(type.type, type.exerciseTypeId)">
              {{ getTypeName(type) }}
            </th>
          </tr>
        </thead>
        <tbody class="c-table__body">
          <tr v-for="categorySelection in data.categorySelection" :key="categorySelection.category.id">
            <td class="c-table__grouped-cells">
              <div class="c-table__cell-content">
                {{categorySelection.category.name}}
              </div>
            </td>
            <td class="c-table__grouped-cells is-first"
                v-for="type in categorySelection.types"
                :key="getTypeKey(type.type, type.exerciseTypeId) + '-' + categorySelection.category.id"
            >
              <div class="c-table__cell-content">
                <sceRouteButton class="c-button--primary c-button--tiny" label="edit"
                  :route="{name: 'admin.event.discipline.dashboard.selections', params: {
                    categoryId: categorySelection.category.id,
                    type: type.type,
                    exerciseTypeId: type.exerciseTypeId ? type.exerciseTypeId : 'all'
                  }}"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find'
  import forEach from 'lodash/forEach'

  import rounds from 'client/lib/rounds'

  export default {
    name: "sessionSelections",
    props: ['sessionId', 'data'],
    computed: {
      session: function () {
        return find(this.$store.state.sessions.items, item => item.id === this.sessionId)
      },
      round: function() {
        return rounds.getRound(this.session.roundIndex)
      },
      eventDiscipline: function () {
        return find(this.$store.state.eventDisciplines.items, item => item.id === this.$route.params.disciplineId)
      },
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline
      },
    },
    created: function () {
      this.init()
    },
    methods: {
      init: function () {},
      select: function(value) {

        forEach(this.data.rankingSelection, selection => {
          forEach(selection.types, type => {
            if (type.rankingId && ! type.empty) {
              type.selected = value
            }
          });
        });
      },
      getTypeKey: function(type, event) {
        let key = type
        if (type === 'EVENTS' || type === 'TEAM-EVENTS') {
          key += '-' + event
        }
        return key
      },
      getTypeName: function(type) {
        let name = ''
        switch (type.type) {
          case 'TEAM-EVENTS':
            name += this.$t('team') + ' ' + this.$t('exercise.type.' + type.exerciseTypeId)
            break
          case 'EVENTS':
            name += this.$t('exercise.type.' + type.exerciseTypeId)
            break
          default:
            name = this.$t('rankingType.' + type.type)
        }

        return name
      }
    },
  }
</script>
