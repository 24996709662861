<template>
  <div class="c-main--flex-1">
    <h2 class="c-title c-title--page">{{$t('export')}}</h2>

    <h3 class="c-title c-title--page-section">{{$t('select.sessions')}}</h3>

    <div class="o-layout">
      <div class="o-layout__item u-1-of-3" v-for="date in sessionsByDate" :key="date.date">
        <h4 class="c-title c-title--page-section">{{date.dateStr}}</h4>
        <div v-for="session in date.sessions" :key="session.id" class="c-input">
          <input v-model="selectedSessions"  :id="'session-' + session.id" type="checkbox"
                 class="c-input__field c-input__field-checkbox" :value="session.id" />
          <label :for="'session-' + session.id" class="c-input__label c-input__label-checkbox">
            {{session.name}}
          </label>
        </div>
      </div>

      <div class="o-layout__item">
        <button href="#" v-on:click.prevent="selectSessions(true)" class="c-button c-button--ghost">
          {{$t('select.all')}}
        </button>
        <button href="#" v-on:click.prevent="selectSessions(false)" class="c-button c-button--ghost">
          {{$t('select.none')}}
        </button>
      </div>
    </div>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar u-margin-bottom-medium">
        <a v-for="tab in $options.tabs" :key="tab" v-on:click="setActiveTab(tab)"
           class="c-button c-button--large c-button--ghost c-tabs__tab-link"
           :class="{'is-active': activeTab === tab}">
          {{$t(tab)}}
        </a>
      </div>
      <div class="c-tabs__panel c-main">
        <div class="o-layout" v-if="activeTab === 'session.order'">
          <div class="o-layout__item u-margin-bottom-large">
            <div class="c-button c-button--primary c-button--large" v-on:click="pdfOrder()">
              {{$t('export.pdf')}}
            </div>
            <div class="c-button c-button--primary c-button--large" v-on:click="exportOrder()">
              {{$t('export.csv')}}
            </div>
          </div>
        </div>
        <div class="o-layout" v-if="activeTab === 'judgepanels'">
          <div class="o-layout__item u-margin-bottom-large">
            <div class="c-button c-button--primary c-button--large" v-on:click="pdfJudges()">
              {{$t('export.pdf')}}
            </div>
            <div class="c-button c-button--primary c-button--large" v-on:click="exportJudges()">
              {{$t('export.csv')}}
            </div>
            <div class="c-button c-button--primary c-button--large" v-on:click="exportJudgesFIG()">
              {{$t('export.judgesFIG')}}
            </div>
          </div>
        </div>
        <div class="o-layout" v-if="activeTab === 'participants'">
          <div class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="participants.type" name="participantsType" label="type"
                       :options="$options.participantListTypes" />
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="participants.status" name="participantsStatus" label="status"
                       :options="$options.participantOptions" />
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceCheckBox v-model="participants.pageBreaks" name="participants.pageBreaks" label="participants.pageBreaks"/>
          </div>
          <div class="o-layout__item u-1-of-1">
            <div class="c-button c-button--primary c-button--large" v-on:click="pdfParticipants()">
              {{$t('export.pdf')}}
            </div>
            <div class="c-button c-button--primary c-button--large" v-on:click="pdfShirtNrs()"
                 :class="{'c-button--disabled': participants.type !== 'bySession' }" >
              {{$t('export.shirtnrs')}}
            </div>
            <div class="c-button c-button--primary c-button--large" v-on:click="exportCSVParticipants()"
                 :class="{'c-button--disabled': participants.type !== 'bySession' }" >
              {{$t('export.csv')}}
            </div>
          </div>
        </div>
        <div class="o-layout" v-if="activeTab === 'results'">
          <div class="o-layout__item u-1-of-1 u-margin-bottom-large">
            <sessionResults key="resultsSelection" :data="results" :judgeScores="false" />
          </div>
        </div>
        <div class="o-layout" v-if="activeTab === 'judgeScores'">
          <div class="o-layout__item u-1-of-1 u-margin-bottom-large">
            <sessionResults key="judgeScoresSelection" :data="judgeScores" :judgeScores="true" />
          </div>
        </div>
        <div class="o-layout" v-if="activeTab === 'resultsBook'">
          <div class="o-layout__item u-1-of-1u-margin-bottom-medium">
            <h4 class="c-title c-title--page-section">{{$t('country-list')}}</h4>
            <sceCheckBox v-model="resultsBook.countryList" name="resultsBook.countryList" label="Include country list"/>
          </div>
          <div class="o-layout__item u-1-of-1u-margin-bottom-medium">
            <h4 class="c-title c-title--page-section">{{$t('session.order')}}</h4>
            <sceCheckBox v-model="resultsBook.order" name="resultsBook.order" label="Include session orders"/>
          </div>
          <div class="o-layout__item u-1-of-1u-margin-bottom-medium">
            <h4 class="c-title c-title--page-section">{{$t('judgepanels')}}</h4>
            <sceCheckBox v-model="resultsBook.judgePanels" name="judgePanels" label="Include judge panels"/>
          </div>
          <div class="o-layout__item u-1-of-1u-margin-bottom-medium">
            <h4 class="c-title c-title--page-section">{{$t('results')}}</h4>
            <table class="c-table--results">
              <thead>
              <tr>
                <th class="c-table-text__regular">Type</th>
                <th class="c-table-text__regular">Regular</th>
                <th class="c-table-text__regular">Open</th>
              </tr>
              </thead>
              <tbody class="c-table__body">
              <tr v-for="(type, key) of resultsBook.results" :key="key">
                <td class="c-table__grouped-cells">
                  {{ $t('rankingType.'+key) }}
                </td>
                <td class="c-table__grouped-cells">
                  <div class="c-table__cell-content">
                    <sceCheckBox v-model="type.regular" :name="'results-' + key + '-regular'" />
                  </div>
                </td>
                <td class="c-table__grouped-cells">
                  <div class="c-table__cell-content">
                    <sceCheckBox v-model="type.open" :name="'results-' + key + '-open'" />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="o-layout__item u-1-of-1u-margin-bottom-medium">
            <h4 class="c-title c-title--page-section">{{$t('judgeScores')}}</h4>

            <table class="c-table--results">
              <thead>
              <tr>
                <th class="c-table-text__regular">Type</th>
                <th class="c-table-text__regular">Regular</th>
                <th class="c-table-text__regular">Open</th>
              </tr>
              </thead>
              <tbody class="c-table__body">
              <tr v-for="(type, key) of resultsBook.judgeScores" :key="key">
                <td class="c-table__grouped-cells">
                  {{ $t('rankingType.'+key) }}
                </td>
                <td class="c-table__grouped-cells">
                  <div class="c-table__cell-content">
                    <sceCheckBox v-model="type.regular" :name="'judgeScores-' + key + '-regular'" />
                  </div>
                </td>
                <td class="c-table__grouped-cells">
                  <div class="c-table__cell-content">
                    <sceCheckBox v-model="type.open" :name="'judgeScores-' + key + '-open'" />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="o-layout__item">
            <div class="c-button c-button--primary c-button--large" v-on:click="printResultsBook()">
              {{$t('export.pdf')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import config from 'client/config';

  import form from '@/lib/form'
  import memberHandler from 'client/lib/member.js';
  import panelHandler from 'client/lib/panelHandler.js';
  import participantHandler from 'client/lib/participant.js';
  import planningLib from 'client/lib/planning.js'
  import rankingLib from "client/lib/ranking"
  import report from 'client/lib/report.js';
  import rounds from 'client/lib/rounds'
  import sessionLib from 'client/lib/session.js';

  import cloneDeep from 'lodash/cloneDeep';
  import find from 'lodash/find';
  import filter from 'lodash/filter';
  import forEach from 'lodash/forEach';
  import includes from 'lodash/includes';
  import join from 'lodash/join';
  import map from 'lodash/map';
  import range from 'lodash/range';
  import reduce from 'lodash/reduce';
  import sortBy from 'lodash/sortBy';
  import unionBy from 'lodash/unionBy';

  import moment from 'moment';
  import store from "@/store";

  export default {
    name: "export",
    components: {
      sessionResults: require('client/components/sessionResults.vue').default,
    },
    participantOptions: [{id: 'all', name: 'all'}].concat(
      form.idNamePairs(['present', 'absent', 'reserve'], 'participation.status.')),
    participantListTypes: form.idNamePairs(
      ['bySession', 'byCategory', 'byClub', 'byClubCount', 'byClubTeams', 'byNOC', 'byNOCCount'],
      'export.participantList.'),
    clubOptions: ['list', 'names', 'teams'],
    tabs: [
      'session.order',
      'judgepanels',
      'participants',
      // 'clubs',
      'results',
      'judgeScores',
      'resultsBook',
    ],
    data: function() {
      return {
        selectedSessions: [],
        results: {
          rankingTypes: [],
          rankingSelection: [],
        },
        judgeScores: {
          rankingTypes: [],
          rankingSelection: [],
        },
        participants: {
          type: 'bySession',
          status: 'all',
          pageBreaks: false,
        },
        clubs: {
          type: 'list'
        },
        resultsBook: {
          countryList: false,
          order: true,
          judgePanels: true,
          results: {
            'AA': {
              regular: true,
              open: false,
            },
            'TEAM-AA': {
              regular: true,
              open: false,
            },
            'EVENTS': {
              regular: true,
              open: false,
            }
          },
          judgeScores: {
            'EVENTS': {
              regular: true,
              open: false,
            }
          }
        },
        activeTab: 'session.order',
      };
    },
    computed: {
      sessions: function() {
        return sortBy(this.$store.state.sessions.items, 'index')
      },
      sessionsByDate: function() {
        const dates = []

        this.sessions.forEach(s => {
          const date = moment(s.date).format('YYY-MM-DD')
          let data = dates.find(i => i.date === date)

          if (! data) {
            data = {
              date,
              dateStr: moment(s.date).format('dddd DD-MM'),
              sessions: [],
            }
            dates.push(data)
          }
          data.sessions.push(s)
        })

        return sortBy(dates, 'date')
      },
      eventDiscipline: function() {
        return find(this.$store.state.eventDisciplines.items, item => {
          return item.id === this.$route.params.disciplineId;
        });
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
    },
    watch: {
      selectedSessions: function() {
        this.results = this.calcRankingTypes(false)
        this.judgeScores = this.calcRankingTypes(true)
      }
    },
    methods: {
      setActiveTab: function(tab) {
        this.activeTab = tab
      },
      selectSessions: function(all) {
        if (all) {
          this.selectedSessions = map(this.sessions, session => session.id)
        }
        else {
          this.selectedSessions = []
        }
      },
      calcRankingTypes: function(judgeScores) {
        let catList = [];
        let rankingTypes = reduce(this.getSessions(), (types, session) => {
          let {rankingTypes, categories} = sessionLib.getRankingTypes(session)
          catList = unionBy(catList, categories, 'id')
          return sessionLib.mergeRankingTypes(types, rankingTypes)
        }, [])

        if (judgeScores) {
          const types = ['AA', 'EVENTS']
          rankingTypes.forEach(round => {
            round.rankingTypes = round.rankingTypes.filter(rt => types.includes(rt.type))
          })
        }

        catList = sortBy(catList, 'index');
        const rankingSelection = map(catList,
          category => sessionLib.getRankingSelections(rankingTypes, category))

        return {
          rankingTypes,
          rankingSelection,
        }
      },
      getSessions: function() {
        return sortBy(filter(this.sessions, session => {
          return includes(this.selectedSessions, session.id);
        }), 'index');
      },
      sessionLabel: function(session) {
        return sessionLib.getSessionLabel(session);
      },
      retrievePanels: function() {
        return this.$http.get(
          config.root + '/eventDisciplines/' + this.eventDiscipline.id + '/selectPanels',
          {params: {sessions: this.selectedSessions}}
        );
      },
      exportJudgesFIG: function() {
        this.retrievePanels().then((result) => {
          const panels = result.data.result

          const judges = []

          this.getSessions().forEach(session => {
            const sessionPanels = filter(panels, i => i.sessionId === session.id)

            sessionPanels.forEach(panel => {
              const panelData = panelHandler.exportPanel(panel)
              console.log(panelData)

              panelData.positions.forEach(p => {
                let judge = judges.find(j => j.id === p.id)
                if (! judge) {
                  judge = {
                    id: p.id,
                    judgeId: p.judgeId,
                    lastName: p.judgeLastName,
                    firstname: p.judgeFirstName,
                    positions: []
                  }
                  judges.push(judge)
                }

                if (! judge.positions.includes(p.figCode)) {
                  judge.positions.push(p.figCode)
                }
              })
            })
          })

          const fields = [{
            label: 'ID Judges',
            value: 'judgeId',
          },{
            label: 'Judge LASTNAME',
            value: 'lastName',
          },{
            label: 'Judge Firstname',
            value: '&',
          },{
            label: 'Judge position',
            value: 'positions',
          }]
          const unwind = ['positions']

          report.publishCSV2(judges, fields, 'judge-panels', unwind)
        });
      },
      exportJudges: function() {
        this.retrievePanels().then((result) => {
          const panels = result.data.result;

          const sessionData = map(this.getSessions(), session => {
            const sessionPanels = sortBy(filter(panels, item => {
              return item.sessionId === session.id;
            }), ['set', 'index']);

            const panelData = map(sessionPanels, panel => {
              return panelHandler.exportPanel(panel);
            });

            return {
              session: session.name,
              date: session.date,
              panels: panelData,
            }
          });

          const data = {
            event: this.eventDiscipline.name,
            sessions: sessionData,
          };

          const fields = [{
            label: this.$t('event'),
            value: 'event',
          },{
            label: this.$t('session'),
            value: 'sessions.session',
          },{
            label: this.$t('date'),
            value: 'sessions.date',
          },{
            label: this.$t('set'),
            value: 'sessions.panels.set',
          },{
            label: this.$t('panel'),
            value: 'sessions.panels.panel',
          },{
            label: this.$t('position'),
            value: 'sessions.panels.positions.position',
          },{
            label: this.$t('type'),
            value: 'sessions.panels.positions.type',
          },{
            label: this.$t('judge.id'),
            value: 'sessions.panels.positions.judgeId',
          },{
            label: this.$t('lastname'),
            value: 'sessions.panels.positions.judgeLastName',
          },{
            label: this.$t('firstname'),
            value: 'sessions.panels.positions.judgeFirstName',
          },{
            label: this.$t('club'),
            value: 'sessions.panels.positions.club',
          },{
            label: this.$t('club.id'),
            value: 'sessions.panels.positions.clubId',
          }];
          const unwind = ['sessions', 'sessions.panels', 'sessions.panels.positions'];

          report.publishCSV2(data, fields, 'judge-panels', unwind);
        });
      },
      pdfJudges: function() {
        console.log('print judges')
        this.retrievePanels().then((result) => {
          const panels = result.data.result;

          let sections = [];
          let first = true

          // group panels per sessions as well as set
          forEach(this.getSessions(), session => {
            if ( ! first ) {
              console.log('add page break')
              sections.push({
                type: 'pagebreak'
              })
            }
            first = false
            const rotationType = sessionLib.getSessionRotationType(session.id)
            const allSets = rotationType !== 'rotation'

            const sets = allSets ? 1 : session.sets;
            for (let set = 1; set <= sets; set++) {
              const sectionPanels = sortBy(filter(panels, item => {
                return item.sessionId === session.id && (allSets || item.set === set);
              }), 'index');

              const sectionPanelData = map(sectionPanels, panel => {
                return panelHandler.exportPanel(panel);
              });

              let sectionTitle = session.name + ' ' + this.$t('judgepanels');
              if (!allSets && session.sets > 1) {
                sectionTitle += ' - ' + this.$t('set') + ' ' + set;
              }

              sections.push({
                title:  sectionTitle,
                type: 'judge-panels',
                data: sectionPanelData,
              });
            }
          });

          report.publishReport(this.eventDiscipline, sections, 'judgePanels');
        });
      },
      getParticipantTitleSuffix: function() {
        let titleSuffix = ''
        const sessions = this.getSessions()
        if (sessions.length !== this.sessions.length) {
          titleSuffix += ' - ' + join(map(this.getSessions(), item => { return item.name; }), ', ')
        }
        return titleSuffix
      },
      prepareClubTeams: function() {
        const clubs = [];

        forEach(this.getSessions(), session => {
          const teamParticipations = sessionLib.getTeamParticipations(session)

          forEach(teamParticipations, teamParticipation => {
            const teamCat = participantHandler.getCategory(teamParticipation)
            const team = participantHandler.getParticipant(teamParticipation)

            let clubItem = find(clubs, item => item.clubId === team.clubId)
            if (! clubItem) {
              const club = participantHandler.getClub(team)
              clubItem = {
                title: club.name,
                clubId: club.id,
                type: 'club-teams',
                groupLabel: this.$t('team'),
                data: [],
              }
              clubs.push(clubItem)
            }

            if (! find(clubItem.data, item => item.id === team.id)) {
              const participants = map(participantHandler.getTeamParticipations(teamParticipation.id), item => {
                const memberCat = participantHandler.getCategory(item)
                const participant = participantHandler.getParticipant(item)

                return {
                  members: participantHandler.getMembers(participant, item.participantType).map(m => ({
                    name: memberHandler.getName(m),
                    club: m.clubId !== team.clubId ? participantHandler.getClub(m).name : null,
                  })),
                  category: memberCat.name,
                }
              })

              clubItem.data.push({
                id: team.id,
                name: team.name,
                category: teamCat.name,
                categoryIndex: teamCat.index,
                participants,
              })
            }
          })
        })

        return sortBy(clubs, 'title')
      },
      prepareParticipantItem: function(participation, round) {
        const category = participantHandler.getCategory(participation)
        const participant = participantHandler.getParticipant(participation)
        const club = participantHandler.getClub(participant)
        const country = this.$store.state.events.countries.find(c => c.id === club.countryId)
        const partRound = find(participation.rounds, i => i.roundIndex === round.index)

        const partData = {
          id: participation.id,
          category: category.name,
          categoryIndex: category.index,
          club: club.name,
          country: country ? this.$t(country.id) : '--',
          countryCode: country?.IOC,
          bib: participation.bib,
          members: participantHandler.getMembers(participant, participation.participantType).map(m => ({
            firstname: m.firstname,
            lastName: m.lastName,
            fullName: memberHandler.getName(m),
            gender: m.gender,
            dob: m.dob,
            accessId: m.accessId,
          })),
          group: participation.participantType === 'group' ? participant.name : null,
          status: this.$t('participation.status.' + partRound.status),
          guest: participation.guest,
          externalId: participation.accessId,
        }

        const teamParticipation = participantHandler.getTeamParticipation(participation)
        if (teamParticipation) {
          const team = participantHandler.getParticipant(teamParticipation)

          partData.teamBib = teamParticipation.bib
          partData.teamName = team.name
        }

        return partData
      },
      prepareParticipants: function() {
        const sessions = this.getSessions()
        const round = rounds.getRound(0)

        const sessionParticipations = sessions.map(s => sessionLib.getParticipations(s))
        let participations = unionBy(...sessionParticipations, 'id')

        if (this.participants.status !== 'all') {
          participations = participations.filter(
            p => participantHandler.getStatus(p, round.index) === this.participants.status)
        }

        return participations.map(p => this.prepareParticipantItem(p, round))
      },
      prepareParticipantsSections: function(participations, cfg) {
        const groups = []

        participations.forEach(p => {
          let group = groups.find(g => g.index === p[cfg.groupField])
          if (! group) {
            group = {
              index: p[cfg.groupField],
              title: p[cfg.labelField],
              type: 'participant-list',
              showClub: cfg.showClub,
              showCategory: cfg.showCategory,
              data: []
            }
            groups.push(group)
          }
          group.data.push(p)
        })

        if (cfg.sortFields) {
          groups.forEach(g => {
            g.data = sortBy(g.data, cfg.sortFields)
          })
        }

        return sortBy(groups, 'index')
      },
      prepareParticipantsBySession: function() {
        const round = rounds.getRound(0)

        let sections = []
        forEach(this.getSessions(), session => {
          let setCount = 1
          let sets = [0]
          if (! session.rotationFree && this.discipline.rotationType === 'rotation') {
            setCount = session.sets
            sets = range(1, setCount + 1)
          }
          forEach(sets, i => {
            const participations = sortBy(filter(sessionLib.getParticipations(session, i), item =>
              this.participants.status === 'all' || item.rounds[0].status === this.participants.status
            ), 'bib')
            let data = map(participations, participation => {
              const partData = this.prepareParticipantItem(participation, round)

              const teamParticipation = participantHandler.getTeamParticipation(participation)
              if (teamParticipation) {
                const team = participantHandler.getParticipant(teamParticipation)

                partData.teamBib = teamParticipation.bib
                partData.teamName = team.name
              }

              return partData
            })
            if (data.length) {
              sections.push({
                session: session.name,
                date: moment(session.date).format('DD/MM/YYYY'),
                set: i,
                sets: setCount,
                data: data,
              })
            }
          })
        })

        return {
          event: this.eventDiscipline.name,
          sections: sections,
        }
      },
      exportCSVParticipants: function() {
        const data = this.prepareParticipantsBySession()

        const fields = [{
          label: this.$t('event'),
          value: 'event',
        },{
          label: this.$t('session'),
          value: 'sections.session',
        },{
          label: this.$t('set'),
          value: 'sections.set',
        },{
          label: this.$t('category'),
          value: 'sections.data.category',
        },{
          label: this.$t('club'),
          value: 'sections.data.club',
        },{
          label: this.$t('bib'),
          value: 'sections.data.bib',
        },{
          label: this.$t('lastname'),
          value: 'sections.data.members.lastName',
        },{
          label: this.$t('firstname'),
          value: 'sections.data.members.firstname',
        },{
          label: this.$t('team') + ' ' + this.$t('bib'),
          value: 'sections.data.teamBib',
        },{
          label: this.$t('team'),
          value: 'sections.data.teamName',
        },{
          label: this.$t('dob'),
          value: 'sections.data.members.dob',
        },{
          label: this.$t('group'),
          value: 'sections.data.group',
        },{
          label: this.$t('status'),
          value: 'sections.data.status',
        },{
          label: this.$t('guest'),
          value: 'sections.data.guest',
        },{
          label: this.$t('externalId'),
          value: 'sections.data.externalId',
        }];
        const unwind = ['sections', 'sections.data', 'sections.data.members'];
        const transforms = [
          (row) => {
            const res = cloneDeep(row)
            res.sections.data.members.dob = res.sections.data.members.dob ?
              moment(res.sections.data.members.dob).format('YYYY-MM-DD') : null
            return res
          }
        ];

        let title = this.$t('participants');
        if (this.participants.status !== 'all') {
          title += '-' + this.$t('participation.status.' + this.participants.status);
        }

        report.publishCSV2(data, fields, title, unwind, transforms);
      },
      pdfParticipants: function() {
        let sections = []
        switch (this.participants.type) {
          case 'byClubTeams': {
            sections = this.prepareClubTeams()
            break
          }
          case 'bySession':
            sections = this.pdfParticipantsBySession()
            break
          case 'byCategory': {
            const participations = this.prepareParticipants()
            const cfg = {
              groupField: 'categoryIndex',
              labelField: 'category',
              sortFields: ['bib', 'club'],
              showClub: true,
              showCategory: false,
            }
            sections = this.prepareParticipantsSections(participations, cfg)
            break
          }
          case 'byClub': {
            const participations = this.prepareParticipants()
            const cfg = {
              groupField: 'club',
              labelField: 'club',
              sortFields: ['bib', 'categoryIndex'],
              showClub: false,
              showCategory: true,
            }
            sections = this.prepareParticipantsSections(participations, cfg)
            console.log(sections)
            break
          }
          case 'byClubCount': {
            const participations = this.prepareParticipants()
            const cfg = {
              groupField: 'club',
              labelField: 'club',
              sortFields: ['bib', 'categoryIndex']
            }
            sections = this.prepareParticipantsSections(participations, cfg)
            // convert sections to counts
            sections = [{
              title: this.$t('clubs'),
              type: 'participant-count',
              groupLabel: this.$t('club'),
              data: sections.map(s => ({
                groupName: s.title,
                count: s.data.length,
              })),
            }]
            break
          }
          case 'byNOC': {
            const participations = this.prepareParticipants()
            const cfg = {
              groupField: 'countryCode',
              labelField: 'country',
              sortFields: ['bib', 'category'],
              showClub: false,
              showCategory: true,
            }
            sections = this.prepareParticipantsSections(participations, cfg)
            // convert sections to clubTeam team format
            sections = [{
              title: this.$t('country-list'),
              type: 'club-teams',
              groupLabel: this.$t('country'),
              data: sections.map(s => ({
                name: s.title,
                category: '',
                participants: s.data.map(d => ({
                  members: d.members.map(m => ({ name: m.fullName })),
                  category: d.category,
                }))
              })),
            }]
            break
          }
          case 'byNOCCount': {
            const participations = this.prepareParticipants()
            const cfg = {
              groupField: 'countryCode',
              labelField: 'country',
              sortFields: ['bib', 'category'],
            }
            sections = this.prepareParticipantsSections(participations, cfg)
            // convert sections to counts
            sections = [{
              title: this.$t('country-count'),
              type: 'participant-count',
              groupLabel: this.$t('country'),
              data: sections.map(s => ({
                groupName: s.title,
                count: s.data.length,
              })),
            }]
            break
          }
        }

        // page breaks and title suffix
        let suffix = ''
        if (this.participants.type !== 'bySession') {
          suffix = this.getParticipantTitleSuffix()
        }
        const hasTeams = this.hasTeams()
        let count = 0
        const finalSections = []
        sections.forEach(s => {
          s.title += suffix

          if (count && this.participants.pageBreaks) {
            finalSections.push({
              type: 'pagebreak',
            })
          }

          if (hasTeams) {
            s.showTeam = true
          }

          finalSections.push(s)
          count++
        })

        report.publishReport(this.eventDiscipline, finalSections, 'participants');
      },
      hasTeams: function() {
        if (! this.discipline.team) return false
        const round = rounds.getRound(0)
        const rr = round.rankings.find(r =>
          (r.rankingType === 'TEAM-AA' || r.rankingType === 'TEAM-EVENTS') && r.enabled)
        return !! rr
      },
      pdfParticipantsBySession: function() {
        const list = this.prepareParticipantsBySession()
        return map(list.sections, section => ({
          title: section.session + (section.sets > 1 ? ' ' + this.$t('set') + ' ' + section.set : ''),
          type: 'participant-list',
          showClub: true,
          showCategory: true,
          data: section.data,
        }))
      },
      prepareNOCList: function() {
        let categories = []

        const sessions = this.getSessions()
        sessions.forEach(session => {
          categories = unionBy(categories, sessionLib.getCategories(session), 'id')
        })

        categories = sortBy(categories, 'index')

        let sections = []

        categories.forEach(category => {
          const participants = store.state.participations.items.filter(p =>
              p.categoryId === category.id && p.participantType !== 'team')
          const nocs = []

          participants.forEach(p => {
            const participant = participantHandler.getParticipant(p)
            const club = participantHandler.getClub(participant)

            let noc = nocs.find(n => n.id === club.countryId)
            if (! noc) {
              const country = this.$store.state.events.countries.find(c => c.id === club.countryId)
              noc = {
                id: country.id,
                groupName: this.$t(country.id),
                count: 0
              }
              nocs.push(noc)
            }

            noc.count++
          })

          sections.push({
            title: category.name + this.$t('country-list'),
            type: 'participant-list',
            groupLabel: this.$t('country'),
            data: nocs,
          });
        })

        return sections
      },
      pdfShirtNrs: function() {
        const list = this.prepareParticipantsBySession()
        console.log(list)
        const sections = map(list.sections, section => {
          const data = sortBy(section.data, ['club', 'bib'])
          return {
            title: section.session + (section.sets > 1 ? ' ' + section.set : ''),
            date: section.date,
            data,
          }
        })

        report.publishBIB(this.eventDiscipline, sections)
      },
      exportOrder: function() {
        planningLib.getStartingOrderSections(this.getSessions(), true).then(data => {
          console.log(data);

          const fields = [{
            label: this.$t('session'),
            value: 'session',
          },{
            label: this.$t('block'),
            value: 'data.blocks.title',
          },{
            label: this.$t('panel'),
            value: 'data.blocks.items.panel',
          },{
            label: this.$t('category'),
            value: 'data.blocks.items.categoryName',
          },{
            label: this.$t('club'),
            value: 'data.blocks.items.clubName',
          },{
            label: this.$t('bib'),
            value: 'data.blocks.items.bib',
          },{
            label: this.$t('name'),
            value: 'data.blocks.items.name',
          },{
            label: this.$t('exercise'),
            value: 'data.blocks.items.exerciseType',
          }];
          const unwind = ['data', 'data.blocks', 'data.blocks.items'];

          let title = this.$t('session.order') + '.csv';
          report.publishCSV2(data, fields, title, unwind);
        })
      },
      pdfOrder: function() {
        planningLib.printStartingOrder(this.eventDiscipline, this.getSessions())
      },
      printResultsBook: async function() {
        let sections = []
        const sessions = this.getSessions()

        if (this.resultsBook.countryList) {
          const nocSections = this.prepareNOCList()
          sections = sections.concat(nocSections)
        }

        if (this.resultsBook.order) {
          const orderSections = await planningLib.getStartingOrderSections(sessions)
          sections = sections.concat(orderSections)
        }

        if (this.resultsBook.judgePanels) {
          const judgeSections = await this.printRBJudges(sessions)
          sections = sections.concat(judgeSections)
        }

        const resultSections = this.printRBRankings(sessions, this.resultsBook.results, false)
        sections = sections.concat(resultSections)
        const scoresSections = this.printRBRankings(sessions, this.resultsBook.judgeScores, true)
        sections = sections.concat(scoresSections)

        report.publishReport(this.eventDiscipline, sections, 'book')
      },
      printRBJudges: function(sessions) {
        return new Promise((resolve) => {
          this.retrievePanels().then((result) => {
            const panels = result.data.result;

            let sections = []

            // group panels per sessions as well as set
            forEach(sessions, session => {
              const rotationType = sessionLib.getSessionRotationType(session.id)
              const allSets = rotationType !== 'rotation'

              const sets = allSets ? 1 : session.sets
              for (let set = 1; set <= sets; set++) {
                const sectionPanels = sortBy(filter(panels, item => {
                  return item.sessionId === session.id && (allSets || item.set === set)
                }), 'index')

                const sectionPanelData = map(sectionPanels, panel => {
                  return panelHandler.exportPanel(panel)
                })

                let sectionTitle = session.name + ' ' + this.$t('judgepanels')
                if (!allSets && session.sets > 1) {
                  sectionTitle += ' - ' + this.$t('set') + ' ' + set
                }

                sections.push({
                  title:  sectionTitle,
                  type: 'judge-panels',
                  data: sectionPanelData,
                })
              }
            })

            resolve(sections)
          })
        })
      },
      printRBRankings: function(sessions, filter, judgeScores = false) {
        const rankingTypes = this.calcRBRankingTypes(sessions, filter)
        const sections = []
        forEach(rankingTypes, categorySelection => {
          forEach(categorySelection.types, selection => {
            const ranking = find(this.$store.state.rankings.items, i => i.id === selection.rankingId)
            const round = rounds.getRound(ranking.roundIndex)

            const filter = {
              judgeScores
            }
            sections.push(rankingLib.preparePrint(
              ranking, categorySelection.category, round, this.discipline, filter))
            sections.push({
              type: 'pagebreak',
            })
          })
        })

        return sections
      },
      calcRBRankingTypes: function(sessions, filter) {
        console.log(filter)
        let catList = [];
        const rankingTypes = reduce(sessions, (types, session) => {
          let {rankingTypes, categories} = sessionLib.getRankingTypes(session)
          catList = unionBy(catList, categories, 'id')
          return sessionLib.mergeRankingTypes(types, rankingTypes)
        }, [])

        catList = sortBy(catList, 'index')
        return map(catList, category => {
          const selection = sessionLib.getRankingSelections(rankingTypes, category)
          selection.types = selection.types.filter(s => {
            console.log(s.type, s.subType)
            return !s.empty && filter[s.type] && filter[s.type][s.subType]
          })
          return selection
        })
      },
    },
  };
</script>

<style scoped>
</style>
