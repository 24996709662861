import store from "@/store";
import moment from "moment";

import sortBy from "lodash/sortBy";
import reduce from "lodash/reduce";

import regions from "@/lib/sortathletes/regions";
import {
  shuffleArray,
  getParticipationClub,
  removeMetaDataFromParticipations,
  getSessionStartTime,
} from '@/lib/sortathletes/common'


/**
 * Divide participations of categories in subDivisions
 * Assume that 1 subdivision has only one session
 * @param categories
 * @param sessions
 * @param roundIndex
 * @param regionId
 */
const createSubDivisions = (categories, sessions, roundIndex, regionId = null) => {
  let result = []

  categories.forEach(category => {
    console.log('create subdivisions', category.name)
    const catRound = category.rounds.find(r => r.roundIndex === roundIndex)
    console.log(catRound)
    const groups = Array.from(Array(catRound.subDivisions), (k,i) => {
      console.log('find category info', k, i)

      const ci = findSessionCategoryInfo(sessions, category.id, i+1, regionId)
      console.log(ci)
      return ci
    })
    console.log(groups)

    const participations =
      store.state.participations.items.filter(p => p.categoryId === category.id && p.participantType !== 'team')

    const clubs = getClubGroups(participations, regionId)

    calculateCapacities(groups, participations.length)

    // if applicable, assign participants from dedicated regions
    assignDedicatedRegions(groups, clubs)

    // assign other participants, if applicable based on region distance
    assignByRegionDistance(groups, clubs)

    groups.forEach(group => {
      result = result.concat(group.participations.map(participation => ({
        participation,
        subDivision: group.subDivision,
      })))
    })

    removeMetaDataFromParticipations(participations)
  })

  return result
}

const findSessionCategoryInfo = (sessions, categoryId, subDivision, regionId = null) => {
  let result = null

  sessions.forEach(session => {
    console.log(session.categories)
    const sessionCategory =
      session.categories.find(sc => sc.categoryId === categoryId && sc.subDivision === subDivision)
    console.log(sessionCategory)
    if (sessionCategory) {
      let regionWeight = 0

      if (regionId) {
        // calculate difference between competition time and 13:00h
        const competitionTime = getSessionStartTime(session)
        if (competitionTime) {
          const refTime = moment('13:00', 'HH:mm')
          regionWeight = Math.round(Math.abs(refTime.diff(competitionTime, 'hours')))
        }
      }

      result = {
        sessionId: session.id,
        categoryId,
        subDivision,
        set: sessionCategory.set,
        regions: sessionCategory.regions,
        regionWeight,
        participations: [],
      }
      console.log(result)
      return false
    }
  })

  console.log(result)
  return result
}

const getClubGroups = (participations, regionId) => {
  const groups = []

  participations.forEach(p => {
    const club = getParticipationClub(p)

    let group = groups.find(g => g.club.id === club.id)
    if (! group) {
      let distance = 0
      if (regionId && club.regionId) {
        distance = regions.distance(regionId, club.regionId)
      }

      group = {
        club,
        distance,
        participations: []
      }
      groups.push(group)
    }
    group.participations.push(p)
  })

  return groups
}

/**
 * calculate capacity per sub division for the category
 * TODO: take into account other categories in the sessions
 * @param category
 * @param groups
 * @param count
 */
const calculateCapacities = (groups, count) => {
  const quotum = Math.ceil(count / groups.length)
  groups.forEach(g => {
    g.quotum = quotum
    g.restQuotum = quotum
  })
}

const assignDedicatedRegions = (groups, clubs) => {
  console.log('assign dedicated regions')
  // order regions by amount of subdivisions involved
  let regions = []
  groups.forEach(group => {
    group.regions.forEach(r => {
      let region = regions.find(r2 => r2.regionId === r)
      if (! region) {
        region = {
          regionId: r,
          groups: [],
        }
        regions.push(region)
      }
      region.groups.push(group)
    })
  })

  regions = sortBy(regions, r => r.groups.length)

  regions.forEach(region => {
    const regionClubs = randomizeClubs(clubs.filter(club => club.club.regionId === region.regionId))

    regionClubs.forEach(club => {
      const group = selectGroup(region.groups, club.participations.length, true)
      addParticipants(club, group, clubs)
    })
  })
}

const assignByRegionDistance = (groups, clubs) => {
  console.log('assign other regions')
  const orderedGroups = sortBy(groups, 'regionWeight')
  const orderedClubs = sortBy(clubs, c => - c.distance)

  console.log(orderedGroups)

  orderedClubs.forEach(club => {
    const group = selectGroup(orderedGroups, club.participations.length, false)
    console.log(club, group)
    addParticipants(club, group, clubs)
  })
}

/**
 * Randomize order of clubs and then sort by decreasing size
 * @param clubs
 */
const randomizeClubs = (clubs) => {
  const items = shuffleArray(clubs)
  return sortBy(items, i => - i.participations.length)
}

const selectGroup = (groups, count, random = true) => {
  let candidates = groups.filter(g => g.restQuotum >= count)
  if (! candidates.length) {
    // if no groups have sufficient rest quotum, select groups with biggest rest quotum
    const maxRestQuotum = reduce(groups, (q, g) => Math.max(q, g.restQuotum), 0)

    if (maxRestQuotum) {
      candidates = groups.filter(g => g.restQuotum === maxRestQuotum)
    } else {
      // no restQuotum left, select group with smallest size
      const minSize = reduce(groups, (q, g) => Math.min(q, g.participations.length), 9999)
      candidates = groups.filter(g => g.participations.length === minSize)
    }
  }

  let index = 0
  if (random) {
    index = Math.floor(Math.random() * candidates.length)
  }

  return candidates[index]
}

/**
 * add club participants to the group and remove club from list
 * @param club
 * @param group
 * @param clubs
 */
const addParticipants = (club, group, clubs) => {
  group.participations = group.participations.concat(club.participations);
  group.restQuotum = Math.max(0, group.restQuotum - club.participations.length)

  const clubIndex = clubs.indexOf(club)
  clubs.splice(clubIndex, 1)
}

// Declare exports
export {
  createSubDivisions,
}
