import { render, staticRenderFns } from "./scoreInput.vue?vue&type=template&id=0ca42880&scoped=true&"
import script from "./scoreInput.vue?vue&type=script&lang=js&"
export * from "./scoreInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca42880",
  null
  
)

export default component.exports