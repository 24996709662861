<template>
  <div class="c-main c-session--order-mixed">
    <div class="c-main--flex-1 o-layout">
      <div class="o-layout__item u-3-of-5-at-small">
        <div class="o-layout" ref="blocks">
          <div class="o-layout__item u-margin-bottom-large c-list"
               v-for="(pBlock, block_index) in prepBlocks" :key="pBlock.block.id">
            <header class="c-list__header">
              <div class="c-list__row u-padding-horizontal-small">
                <h2 class="c-list__header-title">
                  {{getBlockTitle(pBlock.block)}} ({{ pBlock.parts.length }})
                </h2>
              </div>
            </header>
            <div class="c-list__body c-list__body-shadow">
              <draggable v-model="pBlock.parts" :group="{name: 'exercise', pull: ['exercise'], put: ['exercise']}"
                         @start="drag=true" @end="drag=false" @change="flagChange(false)"
                         class="c-session__list" :class="{'has-no-child': pBlock.parts.length === 0}">
                <div v-for="(item,index) in pBlock.parts" type="block"  :key="item.part.id+'|'+item.exerciseTypeId"
                    class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-small"
                    :class="{hasOrderIssue: item.issues.length > 0}">
                  <div class="c-session-block__bib o-layout__item u-1-of-13">
                    <div v-if="item.part.bib" class="">
                      {{item.part.bib}}
                    </div>
                  </div>
                  <div class="c-session-block__participant o-layout__item u-6-of-13">
                    <component :is="'participant-'+ item.part.participantType" :participation="item.part" :compact="compact"/>
                  </div>
                  <div class="o-layout__item u-3-of-13">
                    <p class="c-session-block__participant c-session-block__participant-discipline">
                      <span v-if="! compact" class="name">{{$t('category')}}: </span>
                      <span>{{item.cat.name}}</span>
                    </p>
                  </div>
                  <div class="o-layout__item u-3-of-13">
                    <p class="c-session-block__participant c-session-block__participant-discipline">
                      <span class="name">
                        <span v-if="compact">({{ item.set }}) </span>
                        {{item.options.exerciseTypeId ?
                        $t('exercise.choice') + ': ' + $t('exercise.type.' + item.options.exerciseTypeId) :
                        $t('exercise.type.' + item.exerciseTypeId)}}
                      </span>
                      <span v-if="! compact">{{$t('panel')}} {{item.set}}</span>
                    </p>
                  </div>
                  <div class="c-session-block__remove-button" :id="'remove-part-button-' + block_index + '-' + index">
                    <button v-on:click.prevent="removeBlockParticipation(block_index, index)"
                            class="c-button c-button--ghost c-button--small c-button--icon">
                      <svg role="img" class="c-button__icon">
                        <use xlink:href="images/svg-symbols.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-2-of-5-at-small">
        <div class="o-layout c-session__participants c-session__available-participants">
          <header class="c-list__header o-layout__item">
            <div class="c-list__row u-padding-horizontal-small">
              <h2 class="c-list__header-title">{{$t('session.order.availableParticipations')}}
                {{ this.filteredParts }} / {{ this.totalParts }}</h2>
            </div>
          </header>
          <search-bar :config="$options.searchConfig" v-model="search" class="o-layout__item"/>
          <div class="o-layout__item c-list__body c-session__list-wrapper"
               v-bind:class="{'has-no-child': ! filteredData.length}">
            <p v-if="!filteredData.length" class="c-session__no-participants">{{$t('session.order.noAvailableParticipations')}}</p>
            <div class="c-session__list">
              <div v-for="item in filteredData" :key="item.part.id"
                   class="c-list__row o-layout o-layout--flush u-padding-horizontal-medium">
                <div class="c-session-block__bib o-layout__item u-1-of-8">
                  <div v-if="item.part.bib" >
                    {{item.part.bib}}
                  </div>
                </div>

                <div class="c-session-block__participant o-layout__item u-7-of-8">
                  <component :is="'participant-'+ item.part.participantType" :participation="item.part" :compact="compact" />
                  <p class="c-session-block__meta-info">
                    <span class="c-session-block__label">{{$t('category')}}: </span>
                    <span class="c-session-block__value">{{item.cat.name}}</span>
                  </p>
                </div>

                <draggable v-model="item.exercises" :group="{name: 'exercise', pull: ['exercise'], put: []}"
                           @start="drag=true" @end="drag=false" @change="flagChange(true)"
                           class="c-session-block__exercises-wrapper o-layout__item u-push-1-of-8 u-7-of-8">
                  <template v-for="exercise in item.exercises">
                    <div class="c-button c-button--primary c-button--tiny c-list__row--draggable"
                         :key="item.part.id + ':' + exercise.exerciseTypeId">
                      {{exercise.options.exerciseTypeId ?
                      $t('exercise.choice') + ': ' + $t('exercise.type.' + exercise.options.exerciseTypeId) :
                      $t('exercise.type.' + exercise.exerciseTypeId)}}
                    </div>
                  </template>
                </draggable>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-3-at-small">
        <button class="c-button c-button--tertiary c-button--large" v-on:click.prevent="print()">
          create PDF
        </button>
        <button class="c-button c-button--tertiary c-button--large" @click.prevent="autoOrderParticipations()">
          {{$t('planning.selectOrder')}}
        </button>
      </div>

      <div class="o-layout__item u-2-of-3-at-small u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.sessions'}"
                     class="c-button c-button--ghost c-button--large">
          {{$t("cancel")}}
        </router-link>
        <a v-on:click="submit()" href="#!"
           class="c-button c-button--primary c-button--large">
          {{$t('save')}}
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
  import clone from 'lodash/clone'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import findIndex from 'lodash/findIndex'
  import forOwn from 'lodash/forOwn'
  import map from 'lodash/map'
  import orderBy from 'lodash/orderBy'
  import sortBy from 'lodash/sortBy'

  import _block from 'client/lib/block.js'
  import memberLib from '@/lib/member'
  import _participant from 'client/lib/participant.js'
  import planningLib from '@/lib/planning.js'
  import _session from 'client/lib/session.js'
  import { createMixedOrder } from 'client/lib/sortathletes/mixedOrder'

  import draggable from 'vuedraggable'
  import numeral from 'numeral'

  export default {
    name: "session-order-mixed",
    components: {
      draggable,
      'participant-clubMember': require('client/components/models/participantClubMember.vue').default,
      'participant-group': require('client/components/models/participantGroup.vue').default,
      searchBar: require('client/components/searchBarSmall.vue').default,
    },
    props: ['compact'],
    searchConfig: {
      searchFields: ['bib', 'names', 'club', 'region', 'category'],
      sortFields: ['bib', 'club', 'region', 'category'],
      defaultSortField: 'bib',
    },
    data: function() {
      return {
        totalParts: 0,
        filteredParts: 0,
        freeParticipations: [],
        filteredData: [],
        prepBlocks: [],
        changed: false,
        search: {
          searchString: '',
          sortOrder: 'bib',
          sortDir: 'asc',
        },
      }
    },
    computed: {
      session: function () {
        const sessionId = this.$route.params.sessionId;
        return find(this.$store.state.sessions.items, i => i.id === sessionId)
      },
      blocks: function() {
        const sessionId = this.$route.params.sessionId;
        return filter(this.$store.state.blocks.items, i => i.sessionId === sessionId)
      },
      participations: function() {
        return filter(this.$store.state.participations.items, i => i.participantType !== 'team')
      },
      blockParticipations: function() {
        return this.$store.state.blockParticipations.items;
      },
      members: function() {
        return this.$store.state.members.items;
      },
      groups: function() {
        return this.$store.state.groups.items;
      },
      clubs: function() {
        return this.$store.state.clubs.items;
      },
      categories: function() {
        return this.$store.state.categories.items;
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      set: function() {
        return parseInt(this.$route.params.set);
      }
    },
    created: function () {
      this.initComponent()
    },
    watch: {
      set: function() {
        this.initComponent();
      },
      freeParticipations: function() {
        this.filterData()
      },
      search: function() {
        this.filterData()
      },
    },
    beforeRouteLeave: function (to, from, next) {
      this.preventLeave(next);
    },
    beforeRouteUpdate: function (to, from, next) {
      this.preventLeave(next);
    },
    methods: {
      preventLeave: function(next) {
        if (! this.changed) {
          next();
          return;
        }

        this.$modal.show({
          title: this.$t('unsaved_changes.title'),
          message: this.$t('unsaved_changes.message'),
          onConfirm: () => {
            next();
          },
          onCancel: () => {
            next(false);
          }
        });
      },
      getCategory: function(categoryId) {
        return find(this.categories, item => { return item.id === categoryId; });
      },
      getBlockTitle: function (block) {
        if (typeof block !== 'undefined') {
          return _block.getBlockTitle(block, { full: false })
        } else {
          return ''
        }
      },
      getParticipation: function (participationId) {
        return _participant.getParticipation(participationId);
      },
      getParticipant: function(participation) {
        return _participant.getParticipant(participation);
      },
      getClub: function(participation) {
        const participant = this.getParticipant(participation);
        return _participant.getClub(participant);
      },
      initComponent: function() {
        this.prepareBlocks();
      },
      getParticipations: function() {
        const participations = _session.getParticipationsWithExercises(this.session)
        participations.forEach(p => {
          const cat = _participant.getCategory(p.part)
          const participant = _participant.getParticipant(p.part)
          const club = _participant.getClub(participant)
          p.cat = cat
          p.data = {
            bib: p.part.bib,
            category: cat.name,
            club: club.name,
            region: memberLib.getClubRegion(club)?.name,
            names: _participant.getParticipantNames(p.part)
          }
          const exercises = []

          forOwn(p.exercises, (exercise, type) => {
            exercises.push({
              part: p.part,
              cat: p.cat,
              exerciseTypeId: type,
              set: exercise.set,
              options: exercise.options,
              issues: [],
            })
          })

          p.exercises = exercises
        })
        return participations
      },
      prepareBlocks: function() {
        let freeParticipations = this.getParticipations();
        const blocks = sortBy(this.blocks, 'index');

        this.prepBlocks = map(blocks, block => {
          const parts = map(_block.getBlockParticipations(block.id), item => {
            const part = this.getParticipation(item.participationId);
            if (! part) {
              return {
                missing: true
              }
            }

            const index = findIndex(freeParticipations, i => i.part.id === part.id)
            let exercise = {
              set: 1,
              options: {},
            };
            if (index >= 0) {
              exercise = freeParticipations[index].exercises.find(i => i.exerciseTypeId === item.exerciseTypeId)
              freeParticipations[index].exercises =
                freeParticipations[index].exercises.filter(i => i.exerciseTypeId !== item.exerciseTypeId)
            }

            if (! exercise) console.log(part);
            return {
              part: part,
              cat: this.getCategory(part.categoryId),
              exerciseTypeId: item.exerciseTypeId,
              options: exercise ? exercise.options : {},
              set: exercise ? exercise.set : 1,
              issues: []
            }
          });
          return {
            block: block,
            parts: parts.filter(i => ! i.missing)
          }
        });
        this.freeParticipations = freeParticipations
        this.filterData()

        this.$nextTick(() => {
          this.changed = false
        });
      },
      filterData: function() {
        let filteredData = this.freeParticipations.filter(i => i.exercises.length)
        let total = 0
        filteredData.forEach(fp => {
          total += fp.exercises.length
        })
        this.totalParts = total

        if (this.search.searchString.length) {
          const string = this.search.searchString.toLowerCase();
          filteredData = filter(filteredData, item => {
            const searchFields = this.$options.searchConfig.searchFields;
            const match = find(searchFields, searchField => {
              let value = item.data[searchField];
              if (!value) {
                return false;
              }
              if (Array.isArray(value)) {
                const res = find(value, valItem => {
                  return valItem && valItem.toLowerCase().indexOf(string) >= 0;
                });
                return res !== undefined;
              } else if (typeof value == 'number') {
                value = numeral(value).format('0')
              }
              return value && value.toLowerCase().indexOf(string) >= 0;
            });

            return match !== undefined;
          })
        }

        if (this.search.sortOrder) {
          const sort = 'data.' + this.search.sortOrder
          console.log('sort by', sort)
          filteredData = orderBy(filteredData, sort, this.search.sortDir)
        }

        total = 0
        filteredData.forEach(fp => {
          total += fp.exercises.length
        })
        this.filteredParts = total
        this.filteredData = filteredData
      },
      flagChange: function(filter = false) {
        this.changed = true
        if (filter) {
          this.filterData()
        }
      },
      removeBlockParticipation: function(block_index, part_index) {
        const block = this.prepBlocks[block_index];

        const blockPart = block.parts.splice(part_index, 1)[0];

        const index = findIndex(this.freeParticipations, item =>{ return item.part.id === blockPart.part.id });
        let freePart = null;
        if (index >= 0) {
          freePart = this.freeParticipations[index];
        }
        else {
          freePart = {
            part: blockPart.part,
            cat: blockPart.cat,
            exercises: []
          };
          this.freeParticipations.push(freePart);
        }

        freePart.exercises.push(clone(blockPart))

        this.flagChange(true)
      },
      submit: function() {
        this.$notify.saveAsync(
            this.$t('order'),
            this.$store.dispatch('blocks.saveOrders', {prepBlocks: this.prepBlocks, sessionId: this.session.id}),
            () => {
              this.changed = false;
            }
        );
      },
      print: function() {
        const eventDiscipline = find(this.$store.state.eventDisciplines.items,  (item) => {
          return item.id === this.session.eventDisciplineId;
        });

        planningLib.printStartingOrder(eventDiscipline, [this.session])
      },
      reOrderBlocks: function(blocks) {
        this.freeParticipations = this.getParticipations();

        const prepBlocks = map(blocks, (item) => {
          const parts = item.exercises.map(exercise => {
            const blockPart= {
              cat: exercise.cat,
              part: exercise.part,
              exerciseTypeId: exercise.exerciseTypeId,
              options: {},
              set: exercise.set,
              issues: exercise.issues,
            }
            this.processAddExercise(blockPart)
            return blockPart
          })

          return {
            block: item.block,
            parts
          }
        });

        this.prepBlocks = prepBlocks;
      },
      processAddExercise: function(blockPart) {
        const index = findIndex(this.freeParticipations, item =>{ return item.part.id === blockPart.part.id });
        if (index >= 0) {
          // const item = this.freeParticipations[index].exercises.find(i => i.exerciseTypeId === blockPart.exerciseTypeId)
          this.freeParticipations[index].exercises =
            this.freeParticipations[index].exercises.filter(i => i.exerciseTypeId !== blockPart.exerciseTypeId)
        }
      },
      autoOrderParticipations: function() {
        const eventDiscipline = find(this.$store.state.eventDisciplines.items,
          i => i.id === this.session.eventDisciplineId)
        createMixedOrder(eventDiscipline.planningConfig, this.session).then(blocks => {
          this.reOrderBlocks(blocks)
        })
      }
    },
  };
</script>

<style scoped >
</style>
