<template>
  <div class="c-main--flex-1">
    <div class="o-layout">
      <div class="o-layout__item">
        <h3 class="c-title c-title--page-section">{{getBlockTitle()}} - Rotation Order</h3>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-2-of-3-at-large">
        <div class="o-layout" ref="blocks">
          <div class="o-layout__item u-1-of-2-at-large u-margin-bottom-large c-list"
               v-for="(pBlock, block_index) in prepBlocks" :key="pBlock.exerciseTypeId">
            <header class="c-list__header">
              <div class="c-list__row u-padding-horizontal-small">
                <h2 class="c-list__header-title">
                  {{ $t('exercise.type.' + pBlock.exerciseTypeId) }} ({{ pBlock.parts.length }})
                </h2>
              </div>
            </header>

            <div class="c-list__body c-list__body-shadow">
              <draggable v-model="pBlock.parts" :group="{name: 'participation', pull: false}"
                         @change="changed=true"
                         class="c-session__list" :class="{'has-no-child': pBlock.parts.length === 0}">

                <div v-for="(item,index) in pBlock.parts"
                     :key="(item.filler ? 'filler-' : item.part.id) + '-' + block_index + '-' + index"
                     class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-medium">
                  <template v-if="item.filler">
                    <div class="c-session-block__bib o-layout__item u-1-of-8">
                    </div>
                    <div class="c-session-block__participant o-layout__item u-7-of-8">
                      <div class="name">
                        {{ $t('session.order.blockFiller') }}
                      </div>
                      <div class="c-session-block__remove-button" :id="'remove-part-button-' + block_index + '-' + index">
                        <button v-on:click.prevent="removeBlockParticipation(block_index, index)"
                                class="c-button c-button--ghost c-button--small c-button--icon">
                          <svg role="img" class="c-button__icon">
                            <use xlink:href="images/svg-symbols.svg#close"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="c-session-block__bib o-layout__item u-1-of-8">
                      <div v-if="item.part.bib" class="">
                        {{ item.part.bib }}
                      </div>
                    </div>
                    <div class="c-session-block__participant o-layout__item u-7-of-8">
                      <component :is="'participant-'+ item.part.participantType" :participation="item.part"/>
                      <p class="c-session-block__meta-info">
                        <span class="c-session-block__label">{{ $t('category') }}: </span>
                        <span class="c-session-block__value">{{ item.cat.name }}</span>
                      </p>
                      <div class="c-session-block__remove-button" :id="'remove-part-button-' + block_index + '-' + index">
                        <button v-on:click.prevent="removeBlockParticipation(block_index, index)"
                                class="c-button c-button--ghost c-button--small c-button--icon">
                          <svg role="img" class="c-button__icon">
                            <use xlink:href="images/svg-symbols.svg#close"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <!-- Available Participants -->
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="o-layout c-session__participants c-session__available-participants c-list">

          <header class="o-layout__item c-list__header">
            <div class="c-list__row u-padding-horizontal-small">
              <h2 class="c-list__header-title">{{ $t('session.order.availableParticipations') }}
                {{ filteredData.length }} / {{ freeParticipations.length }}</h2>
            </div>
          </header>

          <search-bar :config="$options.searchConfig" v-model="search" class="o-layout__item"/>

          <div class="o-layout__item c-list__body c-session__list-wrapper">
            <p v-if="!freeParticipations.length" class="c-session__no-participants">
              {{ $t('session.order.noAvailableParticipations') }}</p>

            <draggable v-model="filteredData" :group="{ name: 'participation', pull: 'clone', put: false }"
                       :clone="cloneItem" :move="moveFree"
                       class="c-session__list" :class="{'has-no-child': filteredData.length === 0}">
              <div v-for="(item) in filteredData"
                   class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-medium"
                   :key="item.part.id+'-free'" >
                <div class="c-session-block__bib o-layout__item u-1-of-8">
                  <div v-if="item.part.bib" class="">
                    {{ item.part.bib }}
                  </div>
                </div>
                <div class="c-session-block__participant o-layout__item u-7-of-8">
                  <component :is="'participant-'+ item.part.participantType" :participation="item.part"/>
                  <p class="c-session-block__meta-info">
                    <span class="c-session-block__label">{{ $t('category') }}: </span>
                    <span class="c-session-block__value">{{ item.cat.name }}</span>
                  </p>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.sessions.order.set', params: {set: block.set}}"
                     class="c-button c-button--ghost c-button--large">
          {{ $t("cancel") }}
        </router-link>
        <a v-on:click.prevent="submit()" href="#!"
           class="c-button c-button--primary c-button--large">
          {{ $t('save') }}
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import orderBy from "lodash/orderBy";
import sortBy from 'lodash/sortBy';

import _block from 'client/lib/block.js'
import _participant from 'client/lib/participant'
import _session from 'client/lib/session'

import draggable from 'vuedraggable'
import numeral from "numeral";
import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

export default {
  name: "session-order-rotation",
  components: {
    draggable,
    searchBar: require('client/components/searchBarSmall').default,
    "participant-clubMember": require('client/components/models/participantClubMember.vue').default,
    "participant-group": require('client/components/models/participantGroup.vue').default,
  },
  props: [],
  searchConfig: {
    searchFields: ['bib', 'names', 'club', 'category'],
    sortFields: ['bib', 'club', 'category'],
    defaultSortField: 'bib',
  },
  data: function () {
    return {
      freeParticipations: [],
      filteredData: [],
      prepBlocks: [],
      changed: false,
      search: {
        searchString: '',
        sortOrder: 'bib',
        sortDir: 'asc',
      },
    };
  },
  computed: {
    session: function () {
      const sessionId = this.$route.params.sessionId;
      return find(this.$store.state.sessions.items, item => {
        return item.id === sessionId;
      });
    },
    block: function() {
      const blockId = this.$route.params.blockId;
      return find(this.$store.state.blocks.items, item => item.id === blockId)
    },
    blockParticipations: function() {
      const blockId = this.$route.params.blockId;
      return sortBy(filter(this.$store.state.blockParticipations.items, item => item.blockId === blockId))
    },
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline
    },
  },
  created: function () {
    this.initComponent();
  },
  watch: {
    set: function () {
      this.initComponent();
    },
    freeParticipations: function() {
      this.filterData()
    },
    search: function() {
      this.filterData()
    },
  },
  beforeRouteLeave: function (to, from, next) {
    this.preventLeave(next);
  },
  beforeRouteUpdate: function (to, from, next) {
    this.preventLeave(next);
  },
  methods: {
    preventLeave: function (next) {
      if (!this.changed) {
        next();
        return;
      }

      this.$modal.show({
        title: this.$t('unsaved_changes.title'),
        message: this.$t('unsaved_changes.message'),
        onConfirm: () => {
          next();
        },
        onCancel: () => {
          next(false);
        }
      });
    },
    getBlockTitle: function () {
      if (typeof this.block !== 'undefined') {
        return _block.getBlockTitle(this.block, {full: this.skipSet, addExerciseType: true})
      } else {
        return ''
      }
    },
    initComponent: function () {
      this.skipSet = this.discipline.rotationType === 'mixed' || this.session.rotationFree
      this.setFreeParticipations()
      this.prepareBlocks()
      this.filterData()
    },
    setFreeParticipations: function () {
      const set = this.skipSet ? 0 : this.set
      const participations = _session.getParticipations(this.session, set)
      this.totalParts = participations.length

      this.freeParticipations = map(participations, part => this.createItem(part))

      this.freeParticipations = map(this.blockParticipations, item => {
        const part = _participant.getParticipation(item.participationId)
        return this.createItem(part)
      })
    },
    createItem: function(part) {
      const cat = _participant.getCategory(part)
      const participant = _participant.getParticipant(part)
      const club = _participant.getClub(participant)
      const names = _participant.getParticipantNames(part)
      return {
        part,
        cat,
        data: {
          bib: part.bib,
          names,
          club: club.name,
          category: cat.name,
        }
      }
    },
    filterData: function() {
      let filteredData = this.freeParticipations
      if (this.search.searchString.length) {
        const string = this.search.searchString.toLowerCase();
        filteredData = filter(filteredData, item => {
          const searchFields = this.$options.searchConfig.searchFields;
          const match = find(searchFields, searchField => {
            let value = item.data[searchField];
            if (!value) {
              return false;
            }
            if (Array.isArray(value)) {
              const res = find(value, valItem => {
                return valItem && valItem.toLowerCase().indexOf(string) >= 0;
              });
              return res !== undefined;
            } else if (typeof value == 'number') {
              value = numeral(value).format('0')
            }
            return value && value.toLowerCase().indexOf(string) >= 0;
          });

          return match !== undefined;
        })
      }

      if (this.search.sortOrder) {
        const sort = 'data.' + this.search.sortOrder
        console.log('sort by', sort)
        filteredData = orderBy(filteredData, sort, this.search.sortDir)
      }

      this.filteredData = filteredData
    },
    getBlockParticipations: function (block) {
      let blockParticipations = filter(this.blockParticipations, item => {
        return item.blockId === block.id;
      });

      return sortBy(blockParticipations, 'index');
    },
    getExerciseOrder: function(exerciseTypeId, exerciseTypes) {
      if (this.block.exerciseOrders) {
        if (this.block.exerciseOrders[exerciseTypeId]) {
          return this.block.exerciseOrders[exerciseTypeId]
        } else {
          return []
        }
      }

      if (this.block.rotationOrders) {
        // reverse calculate rotation of the exercise type
        // Does NOT work in combination with rest rotations
        const rotations = this.session.rotations
        const ex_i = exerciseTypes.map(et => et.id).indexOf(exerciseTypeId)
        const r = (rotations + ex_i - this.block.index) % rotations
        if (this.block.rotationOrders[r]) {
          return this.block.rotationOrders[r].participations
        }
      }
      return []
    },

    prepareBlocks: function () {
      const exerciseTypes = this.$store.getters.sessionExerciseTypes(this.session, true)
      console.log(exerciseTypes)

      this.prepBlocks = exerciseTypes.map(exerciseType => {
        const order = this.getExerciseOrder(exerciseType.id, exerciseTypes)
        return {
          exerciseTypeId: exerciseType.id,
          parts: order.map(participationId => {
            const part = _participant.getParticipation(participationId)
            return this.createItem(part)
          })
        }
      })

      this.$nextTick(() => {
        this.changed = false;
      });
    },
    removeBlockParticipation: function (block_index, part_index) {
      console.log('remove bp')
      const block = this.prepBlocks[block_index];

      block.parts.splice(part_index, 1);
      this.changed = true;
    },
    cloneItem: function(item) {
      return clone(item)
    },
    moveFree: function(evt) {
      console.log('on move free')
      console.log(evt)

      const partId = evt.draggedContext.element.part.id
      return 0 > findIndex(evt.relatedContext.list, i => i.part.id === partId)
    },
    submit: function () {
      const data = {}
      this.prepBlocks.forEach(pBlock => {
        data[pBlock.exerciseTypeId] = pBlock.parts.map(bp => bp.part.id)
      })

      this.$notify.saveAsync(
        this.$t('order'),
        this.$store.dispatch('blocks.saveRotationOrder', {blockId: this.block.id, data: data}),
        () => {
          this.changed = false;
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
