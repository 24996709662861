<template>
  <div>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="inputSession">{{$t('session')}}</label>
          <select class="c-input__field c-input__field-select" v-model="selectedSessionId"
                  id="inputSession" name="inputSession"
                  tabindex="0" :disabled="blocked">
            <option v-for="session in sessions" :value="session.id" :key="session.id">
              {{session.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-small u-1-of-3-at-large" v-if="selectedSessionId">
        <div class="c-input">
          <label class="c-input__label" for="inputBlock">{{$t('block')}}</label>
          <select class="c-input__field c-input__field-select" v-model="selectedBlockId"
                  id="inputBlock" name="inputBlock"
                  tabindex="1" :disabled="blocked">
            <option v-for="block in blocks" :value="block.id" :key="block.id">
              {{ blockTitle(block) }}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-small u-1-of-3-at-large" v-if="selectedSessionId">
        <div class="c-input">
          <label class="c-input__label" for="inputExercise">{{$t('exercise')}}</label>
          <select class="c-input__field c-input__field-select" v-model="selectedExercise"
                  id="inputExercise" name="inputExercise" tabindex="2" :disabled="blocked">
            <option v-for="exerciseType in exerciseTypes" :value="exerciseType.id" :key="exerciseType.id" >
              {{$t('exercise.type.' + exerciseType.id)}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-1" v-if="parts && parts.length">
        <div class="c-scores__table" v-if="panelType">
          <div  class="c-scores__table-header">
            <div class="block-part c-exercise__participant">
              {{$t('participant')}}
            </div>
            <div class="c-scores__judge-col">
              <div v-for="valueObject in valueObjects" :key="valueObject.label"
                   class="c-scores__judge-header">
                {{valueObject.label}}
              </div>
            </div>
            <div class="c-scores__total-col">
              <span>{{$t('score.total')}}</span>
            </div>
            <div class="c-scores__total-col">
              <span>{{$t('actions')}}</span>
            </div>
          </div>
          <div v-for="(part, partIndex) in parts" class="c-scores__table-body" :key="part.id">
            <div class="block-part c-exercise__participant">
              <div v-if="part.participation.bib"
                   class="c-exercise__bib">
                {{part.participation.bib}}
              </div>
              <div class="c-exercise__participant-info">
                <component :is="'participant-'+ part.participation.participantType" class="c-exercise__participant-name"
                           :participation="part.participation"/>
                <div class="c-exercise__cat">
                  <span class="c-exercise__cat-label">{{$t('cat')}}</span>
                  {{getCategory(part.participation).name}}
                </div>
              </div>
            </div>
            <template v-if="isActive(part)">
              <div class="c-scores__judge-col">
                <scoreValue v-for="valueObject in valueObjects" :key="part.exercise.id + ':' + valueObject.index"
                            :editing="currentIndex === valueObject.index * 100 + partIndex"
                            :tabIndex="valueObject.index * 100 + partIndex"
                            :value="valueObject"
                            :exerciseId="part.exercise.id" :editInput="editInput"
                            @selectValue="selectValue($event)" @unselectValue="unselectValue($event)" />
              </div>
              <div class="c-scores__total-col">
                <scoreValue
                  :value="getTotalValueObject()" :exerciseId="part.exercise.id" />
              </div>
              <div class="c-scores__total-col">
                <button v-on:click.prevent="clearScores(part.exercise.id)"
                        class="c-button c-button--small c-button--secondary">
                  {{$t('panel.clearScores')}}
                </button>
              </div>
            </template>
            <div class="c-scores__judge-col" v-else>

            </div>

          </div>
        </div>
      </div>
    </div>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout" v-if="blocked">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <button v-on:click.prevent="cancelExercises($event)" tabindex="-1"
                class="c-button c-button--ghost c-button--large">
          {{$t('cancel')}}
        </button>
        <button v-on:click.prevent="finishExercises()"
                class="c-button c-button--primary c-button--large">
          {{$t('finish')}}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
  import clone from 'lodash/clone';
  import find from 'lodash/find';
  import filter from 'lodash/filter';
  import forEach from 'lodash/forEach';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  import blockLib from 'client/lib/block.js';
  import panelHandler from 'client/lib/panelHandler.js';
  import participantHandler from 'client/lib/participant.js';

  import {floor, ceil, round, pow} from 'mathjs';
  import numeral from 'numeral';

  export default {
    name: "scoreinput-block",
    components: {
      'exerciseEdit': require('client/components/models/exercise-edit.vue').default,
      "participant-clubMember": require('client/components/models/participantClubMember.vue').default,
      "participant-group": require('client/components/models/participantGroup.vue').default,
      'scoreValue': require('client/components/models/scoreValue.vue').default,
    },
    data: function() {
      return {
        selectedSessionId: null,
        participations: [],
        selectedBlockId: null,
        selectedBib: null,
        selectedPartId: null,
        exerciseTypes: [],
        selectedExercise: null,
        status: 'idle',
        parts: [],
        panelType: null,
        calculationType: null,
        positions: null,
        currentIndex: '',
        editInput: '',
        valueObjects: [],
        blocked: false,
        blocks: [],
      }
    },
    computed: {
      sessions: function() {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
      categories: function() {
        return this.$store.state.categories.items;
      },
      exercises: function() {
        return this.$store.state.exercises.items;
      },
    },
    watch: {
      selectedSessionId: function() {
        this.initSession();
      },
      selectedBlockId: function() {
        // TODO check for open score

        this.initBlock();
      },
      selectedExercise: function() {
        // TODO check for open score

        this.initBlock();
      },
    },
    created: function() {
      document.onkeyup = evt => {
        this.keyPressed(evt);
      };
    },
    methods: {
      initSession: function() {
        if (this.selectedSessionId) {
          this.blocks = filter(this.$store.state.blocks.items, item => {
            return item.sessionId === this.selectedSessionId;
          });
          this.$store.dispatch('panels.getBySession', {
            sessionId: this.selectedSessionId,
          });
          const session = this.selectedSession();
          this.exerciseTypes = this.$store.getters.sessionExerciseTypes(session);
        } else {
          this.exerciseTypes = [];
        }

        this.selectedBlockId = null;
        this.selectedExercise = null;
        this.selectedPartId = null;
      },
      initBlock: function() {
        if (this.selectedExercise && this.selectedBlockId) {
          this.blocked = true;
          const session = this.selectedSession();
          const block = this.selectedBlock();
          let blockParticipations = filter(
            this.$store.state.blockParticipations.items, i => i.blockId === this.selectedBlockId)
          blockParticipations = blockLib.orderBlockParticipations(blockParticipations, block, session, block.index);

          let exampleExercise = null;
          let categoryId = null;
          this.parts = map(blockParticipations, item => {
            const participation = participantHandler.getParticipation(item.participationId);
            const exercise = find(this.exercises, item => {
              return item.participationId === participation.id
                && item.roundIndex === session.roundIndex
                && item.exerciseTypeId === this.selectedExercise;
            });

            if (! exampleExercise && exercise) {
              exampleExercise = exercise;
              categoryId = participation.categoryId;
            }

            return {
              participation: participation,
              exercise: exercise,
            };
          });

          if (exampleExercise) {
            this.$store.dispatch('exercise.load', {exerciseId: exampleExercise.id, pass: 1}).then(() => {
              const panel = panelHandler.getPanel(session, categoryId, exampleExercise.exerciseTypeId)
              this.panelType = find(this.$store.state.eventDiscipline.panelTypes, item => item.id === panel.panelTypeId)

              this.positions = panelHandler.calculatePositions(panel, this.panelType)
              const types = this.panelType.calculationTypes
              const configuration = this.$store.state.exercise.exerciseConfiguration
              this.calculationType = find(types, type => {
                return type.name === configuration.calculationType
              })

              let valueObjects = []
              forEach(this.calculationType.scoreGroups, (group, groupIndex) => {
                forEach(group.elements, (element, elementIndex) => {
                  const positions = panelHandler.getGroupJudgePositions(group.key, this.panelType);
                  if (positions) {
                    forEach(positions, (position, index) => {
                      valueObjects.push({
                        properties: {
                          group: group.key,
                          element: element.key,
                          leg: 1,
                          position: position,
                          index: 0,
                        },
                        label: this.$t('score.' + element.key).substr(0, 1).toUpperCase() + (index + 1),
                        index: (100 * (groupIndex + 1) + 10 * elementIndex + index),
                        valueType: element.valueType,
                      });
                    });
                  }
                });
              });
              this.valueObjects = valueObjects;
            });
          }
        }
        else {
          this.parts = [];
          this.blocked = false;
        }
      },
      selectedSession: function() {
        return find(this.sessions, item => {
          return item.id === this.selectedSessionId;
        });
      },
      selectedBlock: function() {
        return find(this.blocks, item => {
          return item.id === this.selectedBlockId;
        });
      },
      getCategory: function(participation) {
        return find(this.categories, item => {
          return item.id === participation.categoryId;
        })
      },
      getGroupJudgePositions: function (groupKey) {
        return panelHandler.getGroupJudgePositions(groupKey, this.panelType);
      },
      externalClose: function() {
        this.exerciseId = null;
        this.selectedPartId = null;
      },
      clearScores: function(exerciseId) {
        this.$store.dispatch("exercise.clearValues", {exerciseId: exerciseId, status: 'open', pass: 1});
      },
      blockTitle: function(block) {
        return  this.$t('rotation') + ' ' + (block.index+1) + ' ' + this.$t('panel') + ' ' + block.set;
      },
      selectValue: function (event) {
        const selectedIndex = event.index;
        if (selectedIndex === this.currentIndex) {
          return;
        }
        this.saveInput();
        this.currentIndex = event.index;
      },
      unselectValue: function(event) {
        const selectedIndex = event.index;
        if (selectedIndex !== this.currentIndex) {
          return;
        }
        this.saveInput();
        this.currentIndex = undefined;
      },
      getTotalValueObject: function () {
        const group = find(this.calculationType.scoreGroups, group => {
          return group.key === 'total';
        });

        return {
          properties: {leg: 1, group: 'total', element: 'total', position: -1, index: -1},
          scoreValue: null,
          valueType: group.total.valueType,
        };
      },
      saveInput: function() {
        if (this.currentIndex && this.editInput.length > 0) {
          let value = numeral(this.editInput).value();
          const valueObject = this.getValueObject(this.currentIndex);
          const valueType = valueObject.valueType;
          const factor = pow(10, valueType.precision);
          value = round(factor * value) / factor;
          let opts = clone(valueObject.properties);
          opts.leg = 1;
          opts.value = value;

          const part = this.parts[this.currentIndex % 100];
          opts.exerciseId = part.exercise.id;

          console.log('save score', opts.exerciseId);
          this.$store.dispatch('exercise.setScoreValue', opts);
          this.editInput = '';
        }
      },
      getValueObject: function(valueIndex) {
        const index = floor(valueIndex / 100);
        return find(this.valueObjects, valueObject => {
          return valueObject.index === index;
        });
      },
      valuePressed: function (digit) {
        const value = this.getValueObject(this.currentIndex);
        const newInput = this.editInput + digit;
        const val = numeral(newInput).value();
        if (val > value.valueType.max + 0.0000001) {
          return;
        }
        let dotIndex = newInput.indexOf('.');
        if (dotIndex >= 0) {
          if (newInput.length - ++dotIndex > ceil(value.valueType.precision)) {
            return;
          }
        }
        this.editInput = newInput;
      },
      keyPressed: function(event) {
        if (this.currentIndex) {
          if (event.keyCode === 27) {
            this.editInput = '';
          } else if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
            this.valuePressed(event.key);
          }
          else if (event.keyCode === 8) {
            this.editInput = this.editInput.substr(0, this.editInput.length - 1);
          }
          else if (event.keyCode === 110 || event.keyCode === 190) {
            if (this.editInput.indexOf('.') >= 0) {
              return;
            }
            this.editInput += '.';
          }

          event.preventDefault();
          return false;
        }
      },
      isActive: function(part) {
        const roundIndex = this.selectedSession().roundIndex
        const partRound = find(part.participation.rounds, item => item.roundIndex === roundIndex)
        if (partRound.status !== 'present' || ! part.exercise) {
          return false
        }

        return part.exercise.status !== 'dns';
      },
      cancelExercises: function() {
        forEach(this.parts, part => {
          if (! this.isActive(part)) {
            return;
          }

          this.$store.dispatch('exercise.cancel', {exerciseId: part.exercise.id, nopanel: true});
          this.selectedExercise = null;
        });
      },
      finishExercises: function() {
        forEach(this.parts, part => {
          if (! this.isActive(part)) {
            return;
          }

          this.$store.dispatch('exercise.finishPass', {exerciseId: part.exercise.id, nopanel: true});
          this.selectedExercise = null;
        });
      }
    },
  }
</script>

<style scoped>
 .block-part {
   width: 300px;
 }

 .c-scores__table-header .c-scores__judge-col {
   margin: 0;
 }
</style>
