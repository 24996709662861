<template>
  <div class="c-main">
    <div class="c-title-wrapper">
      <h2 class="c-title c-title--page">{{session.name}} - {{$t('session.order')}}</h2>
      <div class="c-button-group">
        <button class="c-button c-button--primary c-button--small" v-on:click.prevent="compact = false">
          Expanded Layout</button>
        <button class="c-button c-button--primary c-button--small" v-on:click.prevent="compact = true">
          Compact Layout</button>
      </div>
    </div>
    <div class="c-main c-tabs" :class="{'c-session--compact': compact}"  ref="sessionWrapper">
      <div class="c-tabs__tab-bar" v-if="rotationType !== 'mixed' && ! session.rotationFree">
        <router-link
          v-for="set in getSets()"  :key="set"
          class="c-button c-button--large c-button--ghost c-tabs__tab-link" exact
          :to="{ name: 'admin.event.discipline.sessions.order.set', params: {set: set+1, sessionId: $route.params.sessionId}}"
        >
          {{$t(setLabel)}} {{set+1}}
        </router-link>
      </div>

      <div class="c-main c-tabs__panel u-margin-top-medium" id="panel">
        <router-view :compact="compact"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import range from 'lodash/range';

  import sessionLib from 'client/lib/session'

  export default {
    name: "session-order",
    data: function() {
      return {
        setLabel: 'panel',
        compact: false,
      };
    },
    mounted() {
      this.sessionsWrapper = this.$refs.sessionWrapper
    },
    computed: {
      session: function () {
        const sessionId = this.$route.params.sessionId;
        return find(this.$store.state.sessions.items, (item) => {
          return item.id === sessionId;
        });
      },
      rotationType: function() {
        return sessionLib.getSessionRotationType(this.$route.params.sessionId)
      }
    },
    created: function() {
      this.setLabel = sessionLib.getSetLabel();
    },
    methods: {
      getSets: function() {
        return range(this.session.sets);
      },
    }
  };
</script>

<style>
</style>
